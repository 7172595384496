<template>
  <div class="talentList2">
    <div class="head">
      <div class="update-time-wrapper">
        <div class="interview-time-text">面试时间</div>
        <div class="update-btn" @click="goUpdateDate">更新</div>
      </div>
      <div class="tab-wrapper">
        <div class="tab" :class="{ active: tabActiveIndex === 1 }" @click="updateActiveIndex(1)">待面试</div>
        <div class="tab" :class="{ active: tabActiveIndex === 2 }" @click="updateActiveIndex(2)">待反馈</div>
        <div class="tab" :class="{ active: tabActiveIndex === 3 }" @click="updateActiveIndex(3)">已反馈</div>
        <div class="tab" :class="{ active: tabActiveIndex === 4 }" @click="updateActiveIndex(4)">已失效</div>
      </div>
    </div>
    <div class="menue-wrapper"></div>
    <div class="list-wrapper" v-if="resumeList && resumeList.length">
      <candidate-info-item v-for="item in resumeList" :key="item.jobSeekerChannelId + Math.random()" :personItem="item" @tap="goDetail(item)" :isAnonymous="isAnonymous"> </candidate-info-item>
      <!-- <person-info-item v-for="item in resumeList" :key="item.jobSeekerChannelId + Math.random()" :personItem="item" @tap="goDetail(item)">
        <template v-slot:right>
          <div class="right-slot">
            <div class="row1">
              <div class="text">面试时间</div>
              <div class="time">
                {{
                  `${moment(parseInt(item.interviewStartTime)).format('YYYY/MM/DD')}   ${moment(parseInt(item.interviewStartTime)).format('HH:mm')}-${moment(parseInt(item.interviewEndTime)).format(
                    'HH:mm'
                  )}`
                }}
              </div>
              <div class="recommendName" v-if="item.recommendName">{{ item.recommendName }}推荐</div>
            </div>
          </div>
        </template>
      </person-info-item> -->
    </div>
    <div v-else>
      <div class="nocandidate">
        <!-- <img src="@/assets/nocandidate.png" /> -->
        <img src="@/assets/img/no-data.png" />
        <div class="text">暂无候选人</div>
      </div>
    </div>
    <el-dialog
      :visible.sync="videoDialog"
      top="25vh"
      width="100%"
      @open="openVanPopup"
      @close="closeVanPopup"
      class="video-popup"
      lock-scroll
      :show-close="false"
      @close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <video-player ref="videoPlayer" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions"></video-player>
    </el-dialog>

    <float-button v-if="!showDrawer">
      <div class="help-button" id="HelpButton" @click="videoDialog = true">
        <img src="@/assets/img/help-video@2x.png" width="36px" height="46px" />
        <div>
          <span>帮助视频</span>
          <i class="el-icon-caret-right" :size="14"></i>
          <!-- <van-icon name="play-circle-o" :size="14"></van-icon> -->
        </div>
      </div>
      <div class="float-wrapper">
        <div class="float-item" @click="navComment">
          <img src="@/assets/img/search-icon.svg" />
          <div class="float-item-text">搜索</div>
        </div>
        <div class="float-item" @click="goUpdateDate">
          <img src="@/assets/img/time-icon.svg" />
          <div class="float-item">面试<br />管理</div>
        </div>
      </div>
      <!-- <div><img src="@/assets/img/circle.png" @click="navComment" /></div> -->
    </float-button>

    <!-- <float-button v-if="!showDrawer">
      <div><img src="@/assets/img/circle.png" @click="navComment" /></div>
    </float-button> -->
    <van-popup v-model="showDrawer" position="right" :close-on-click-overlay="true">
      <drawer @updateShowDrawer="updateShowDrawer" :drawerType="2" :recommendType="tabActiveIndex" @updateFilterForm="updateFilterForm" />
    </van-popup>
  </div>
</template>
<script>
import FloatButton from '@/components/FloatButton.vue'
import Drawer from '@/components/Drawer.vue'
// import PersonInfoItem from '@/components/PersonInfoItem.vue'
import { searchInterviewResume } from '@/api/candidate'
import moment from 'moment'
import candidateInfoItem from '../components/candidate-info-item1.vue'
import store from '@/store'

export default {
  components: {
    FloatButton,
    Drawer,
    // PersonInfoItem,
    candidateInfoItem,
  },
  data() {
    return {
      moment,
      tabActiveIndex: 1,
      status: 1,
      showDrawer: false,
      filterForm: {
        positionId: '',
        startWorkYear: '',
        endWorkYear: '',
        recommendStatus: '',
        email: '',
      },
      resumeList: [],
      videoDialog: false,
      muted: false,
    }
  },

  created() {
    if (this.$route.query.tabActiveIndex) {
      this.tabActiveIndex = parseInt(this.$route.query.tabActiveIndex)
    }
    // document.querySelector('.video-player-logo').style.height = this.$refs.videoPlayer.$el.offsetHeight + 'px'

    this.reqInterviewerResume()
  },
  computed: {
    isAnonymous() {
      const config = this.$store.state.evaluationConfig || {}
      return config.screeningInfoStatus === 1 ? true : false
    },
    playerOptions() {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: 'https://aimianshiguan.oss-accelerate.aliyuncs.com/resume/76748874068025989.mp4', // url地址
          },
        ],
        // poster: this.currentPoster, // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      }
    },
  },
  methods: {
    openVanPopup() {
      this.videoDialog = true
      setTimeout(() => {
        this.$refs.videoPlayer.$el.style.display = 'block'
      }, 0)
    },
    closeVanPopup() {
      this.videoDialog = false
      this.$refs.videoPlayer.$el.style.display = 'none'
    },
    unmute() {
      const player = this.$refs.videoPlayer.player
      player.muted(this.muted)
      this.muted = !this.muted
    },
    updateFilterForm(filterForm) {
      this.filterForm = filterForm
      this.reqInterviewerResume()
    },
    updateActiveIndex(index) {
      this.tabActiveIndex = index
      this.reqInterviewerResume()
    },
    goDetail(item) {
      this.$store.commit('setShortList', this.resumeList)
      this.$router.push(`/shareurl?jobseekerChannelId=${item.jobSeekerChannelId}&activeIndex=3&interviewPlanId=${item.interviewPlanId}&canBack=1&from=interviewPlan`)
    },
    reqInterviewerResume() {
      // email (string, optional): 候选人邮件 ,
      // interviewResumeStatus (integer, optional): 1-待面试 2-待反馈 3-已反馈 4-已失效 ,
      // interviewerId (integer, optional),
      // maxWorkAge (integer, optional): 最大工作年限 ,
      // minWorkAge (integer, optional): 最小工作年限 ,
      // name (string, optional): 候选人姓名 ,
      // phone (string, optional): 候选人手机号码 ,
      // positionId (string, optional): 职位ID ,
      this.resumeList = []
      searchInterviewResume({
        interviewerId: this.$store.state.userInfo.userId,
        email: this.filterForm.email,
        maxWorkAge: this.filterForm.endWorkYear,
        minWorkAge: this.filterForm.startWorkYear,
        name: this.filterForm.email,
        phone: this.filterForm.email,
        positionId: this.filterForm.positionId,
        interviewResumeStatus: this.tabActiveIndex,
        eduBegin: this.filterForm.eduBegin ? this.filterForm.eduBegin : null,
        eduEnd: this.filterForm.eduEnd ? this.filterForm.eduEnd : null,
      }).then(res => {
        if (res.code === 0) {
          this.resumeList = res.data || []
          this.$store.commit('setShortList', this.resumeList)
        }
      })
    },
    navComment() {
      this.showDrawer = true
    },
    goUpdateDate() {
      this.$router.push('/datePicker')
    },

    updateShowDrawer(flag) {
      this.showDrawer = flag
    },

    translateStatusToText(status) {
      switch (status) {
        case 1:
          return '安排面试'
        case 2:
          return '待用人部门筛选'
        case 3:
          return '已入职'
      }
    },

    translateStatusToColor(status) {
      switch (status) {
        case 1:
          return '#44D7B6'
        case 2:
          return '#D78344'
        case 3:
          return '#1890FF'
      }
    },

    floatButtonClick() {
      alert('click')
    },
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('getEvaluationConfig').finally(() => {
      next()
    })
  },
}
</script>
<style lang="scss">
.talentList2 {
  width: 100%;
  padding-top: 3.2rem;
  .help-button {
    width: 100px;
    height: 46px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    > div {
      height: 32px;
      line-height: 14px;
      width: 80px;
      background: linear-gradient(267deg, #1990ff 0%, #2c25ff 100%);
      border-radius: 16px;
      margin-top: 10px;
      padding: 2px 0 0 10px;
      box-sizing: border-box;
      vertical-align: middle;
      position: relative;
      right: 20px;
      > span {
        display: inline-block;
        width: 30px;
        font-size: 12px;
      }
      i {
        margin-right: 2px;
        position: relative;
        top: -6px;
      }
    }
    > img {
      float: left;
      margin-top: -10px;
      position: relative;
      z-index: 10;
      left: -20px;
    }
  }
  .float-wrapper {
    padding: 15px 0;
    height: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //padding: 15px 14px;
    .float-item {
      .float-item-text {
        text-align: center;
      }
    }
  }

  .head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 10;
    border-bottom: 0.03rem solid rgba($color: #cccccc, $alpha: 0.4);
    .update-time-wrapper {
      padding: 0.4rem 8vw;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .interview-time-text {
        font-size: 0.4rem;
        color: #333333;
      }
      .update-btn {
        cursor: pointer;
        background: rgba(255, 255, 255, 1);
        border: 0.01rem solid #1890ff;
        color: #1890ff;
        padding: 0.1rem 0.5rem;
      }
    }

    .tab-wrapper {
      width: 100%;
      display: flex;
      font-size: 0.37rem;
      padding: 0.35rem 8vw;
      border-top: 0.01rem solid rgba(204, 204, 204, 0.4);
      border-bottom: 0.01rem solid rgba(204, 204, 204, 0.4);
      justify-content: space-between;
      .tab {
        text-align: center;
        width: 1.6rem;
        font-size: 0.4rem;
      }
      .active {
        color: #1f93fd;
      }
    }
  }

  .list-wrapper {
    width: 100%;

    .right-slot {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      .row1 {
        font-size: 0.32rem;
        text-align: left;
        padding-right: 0.5rem;
        .text {
          color: #333333;
          font-size: 0.35rem;
        }
        .time {
          margin-top: 0.2rem;
          color: #b3b3b3;
          font-size: 0.35rem;
        }
        .recommendName {
          margin-top: 0.2rem;
          color: #b3b3b3;
          font-size: 0.35rem;
        }
      }
    }
  }

  .nocandidate {
    width: 65%;
    margin: 20px auto;
    img {
      width: 100%;
    }
    .text {
      margin-top: 20px;
      color: #666666;
      text-align: center;
      font-size: 20px;
    }
  }
  .van-popup--right {
    width: 80%;
    height: 100%;
  }
}
.video-popup .el-dialog__body {
  padding: 0;
}
.video-popup .el-dialog__header {
  display: none;
}
</style>
